import { ACADEMIC_TYPE } from '@common/type/CourseTypes';
import { EventTag, GtmAcademicType } from '@common/type/GoogleTagType';
import { ProductAffiliation } from '@common/type/ProductType';
import TagManager from 'react-gtm-module';
import {
  AddToCartCourseType,
  ClickCourseCardType,
  CreateGoogleAnalyticItemWithoutPriceParams,
  Ga4Item,
  PageViewContentCourseDetailType,
} from 'src/interfaces';

import { BundleDetailEntity } from '@redux/bundle/type/BundleDetailType';
import { CourseDetailEntity } from '@redux/course/type/courseDetailType';

export const sendPageViewContentCourseDetail = (data: PageViewContentCourseDetailType) => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTag.PAGE_VIEW_CONTENT,
      detailProduct: {
        ...data,
      },
    },
  });
};

export const sendAddToCardCourseDetail = (data: AddToCartCourseType) => {
  TagManager.dataLayer({
    dataLayer: {
      event: EventTag.ADD_TO_CART,
      addToCartProducts: [
        {
          ...data,
        },
      ],
    },
  });
};

export const sendClickCourseCard = (data: ClickCourseCardType) => {
  TagManager.dataLayer({
    dataLayer: {
      event: data.event,
      itemSource: data.item_source,
      courseCardDetail: data.items,
    },
  });
};

export const createGoogleAnalyticItemWithoutPrice = (params: CreateGoogleAnalyticItemWithoutPriceParams) => {
  const {
    productName,
    productAffiliation = ProductAffiliation.SKILL_LANE,
    gtmProductBrand,
    mainCategory,
    subCategory,
    gtmContentId,
  } = params;
  return {
    item_name: productName,
    affiliation: productAffiliation,
    index: null,
    item_brand: gtmProductBrand || null,
    item_category: gtmProductBrand || null,
    item_category2: gtmProductBrand || null,
    item_category3: mainCategory || null,
    item_category4: subCategory || null,
    item_category5: null,
    item_list_id: null,
    item_list_name: null,
    item_variant: gtmContentId || null,
    location_id: null,
    quantity: 1,
  };
};

export const createGoogleAnalyticItem = ({
  courseDetail,
  bundleDetail,
  courseName,
  academicType,
}: {
  courseDetail?: CourseDetailEntity;
  bundleDetail?: BundleDetailEntity;
  courseName: string;
  academicType: GtmAcademicType;
}): Ga4Item => {
  return {
    item_id: courseDetail?.price[academicType]?.sku || bundleDetail?.price?.sku || '',
    item_name: courseName,
    affiliation: (courseDetail || bundleDetail)?.productAffiliation || null,
    coupon: null,
    discount: 0,
    index: null,
    item_brand: (courseDetail || bundleDetail)?.gtmProductBrand || null,
    item_category: (courseDetail || bundleDetail)?.gtmProductBrand || null,
    item_category2: (courseDetail || bundleDetail)?.gtmProductBrand || null,
    item_category3: courseDetail?.mainCategory || null,
    item_category4: courseDetail?.subCategory || null,
    item_category5: null,
    item_list_id: null,
    item_list_name: null,
    item_variant: (courseDetail || bundleDetail)?.gtmContentId || null,
    location_id: null,
    price: courseDetail?.price[academicType]?.salePrice || bundleDetail?.price?.salePrice || 0,
    quantity: 1,
  };
};
