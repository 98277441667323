import { ICourse } from 'src/interfaces';

export enum LearningTypeId {
  REG = 1,
  ACM = 2,
  CPD = 3,
  MCL = 4,
  EXL = 5,
}

export type CPDProduct = {
  courses: ICourse[];
  bundles?: ICourse[];
};
