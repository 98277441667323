import { ACADEMIC_TYPE } from './CourseTypes';

export enum ProductAffiliation {
  SKILL_LANE = 'SkillLane',
}

export enum ProductSaleType {
  REGULAR = ACADEMIC_TYPE.REGULAR,
  AUDIT = ACADEMIC_TYPE.AUDIT,
  CREDIT = ACADEMIC_TYPE.CREDIT,
  UPGRADE = ACADEMIC_TYPE.UPGRADE,
}
