import { ACADEMIC_TYPE } from './CourseTypes';

export enum EventTag {
  PAGE_VIEW_CONTENT = 'PageViewContent',
  ADD_TO_CART = 'AddToCart',
  CLICK_COURSE_CARD = 'ClickCourseCard',
  CLICK_COURSE_CARD_HOVER = 'ClickCourseCardHover',
}

export enum ProductBrand {
  CPD = 'CPD',
  ACADEMIC = 'Academic',
  ON_DEMAND = 'On-Demand',
}

export enum ItemSource {
  COURSE_SUGGESTION = 'CourseSuggestion',
}

export type GtmAcademicType =
  | ACADEMIC_TYPE.REGULAR
  | ACADEMIC_TYPE.AUDIT
  | ACADEMIC_TYPE.CREDIT
  | ACADEMIC_TYPE.UPGRADE;
